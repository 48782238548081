import React, { Component } from "react"
import PropTypes from "prop-types"
import Rellax from "rellax"

export class ParallaxDOM extends Component {
  constructor(props) {
    super(props)
    this.state = {
      className: props.className,
      speed: props.speed,
    }
  }

  shouldComponentUpdate() {
    return false
  }

  componentDidMount() {
    // window.addEventListener("scroll", this.handleScroll);
    if (this.state.speed > 0) {
      this.rellax = new Rellax(this.refs.rellax, {
        speed: this.state.speed,
      })
    }
  }

  render() {
    return <div className={this.state.className} ref="rellax" />
  }
}

ParallaxDOM.propTypes = {
  className: PropTypes.string.isRequired,
  speed: PropTypes.number.isRequired,
}

export default ParallaxDOM
