import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import styled from "styled-components"

import ScrollTrigger from "react-scroll-trigger"
import CountUp from "react-countup"
import BackgroundImage from "gatsby-background-image"

import { StyledRecordContainer } from "./style"

const Record = ({ subtitle, title, record }) => (
  <div className="record-container">
    <div className="record">
      <h4 className="record-subtitle">{subtitle}</h4>
      <h3 className="record-title">{title}</h3>
      <CountUp
        start={0}
        end={record}
        suffix="+"
        seperator="."
        decimal=","
        delay={0}
        className="record-number"
      />
    </div>
  </div>
)

Record.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  record: PropTypes.number,
}

const RecordsData = () => {
  return (
    <StyledRecordContainer>
      <div id="records">
        <Record
          subtitle="TOTAL 2019"
          title="MAIN EVENT VISITORS"
          record={2400}
        />
        <Record subtitle="TOTAL PARTICIPANTS" title="ACADEMY" record={2500} />
        <Record
          subtitle="TOTAL PARTICIPANTS"
          title="COMPETITION"
          record={5300}
        />
        <Record subtitle="TOTAL PARTICIPANTS" title="SEMINAR" record={30000} />
        <Record subtitle="TOTAL NUMBER OF" title="SPEAKERS" record={200} />
        <Record subtitle="TOTAL NUMBER OF" title="EXHIBITORS" record={330} />
      </div>
    </StyledRecordContainer>
  )
}

const Records = () => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(
          relativePath: { eq: "images/landing_page/bg-records-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 490, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(
          relativePath: { eq: "images/landing_page/bg-records-desktop.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 491px)`,
    },
  ]

  const [started, setStarted] = useState(false)

  const onEnterViewport = () => {
    // trigger the animation
    // by setting the state
    setStarted(true)
  }

  return (
    <ScrollTrigger onEnter={onEnterViewport}>
      {started ? (
        <BackgroundImage Tag="section" fluid={sources}>
          <RecordsData />
        </BackgroundImage>
      ) : (
        <></>
      )}
    </ScrollTrigger>
  )
}

const StyledRecords = styled(Records)`
  background-size: auto;
  background-color: transparent;
`

export default StyledRecords
