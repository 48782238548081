import React, { Component } from "react"
import TopRightCirclesCSS from "./TopRightCirclesCSS"
import Parallax from "../CircleParallax/Parallax"
import ParallaxDOM from "../CircleParallax/ParallaxDOM"
import image2 from "../../../../../assets/images/parallax_assets/jellybean_orange.png"
import image5 from "../../../../../assets/images/parallax_assets/jellybean_orange2.png"
import image7 from "../../../../../assets/images/parallax_assets/jellybean_purple3.png"
import image8 from "../../../../../assets/images/parallax_assets/jellybean_blue4.png"

class TopRightCircles extends Component {
  constructor(props) {
    super(props)
    this.mediaQuery =
      typeof window !== "undefined" &&
      window.matchMedia("screen and (max-width: 768px)")
    this.state = {
      isMobile: this.mediaQuery.matches,
    }
  }

  componentDidMount() {
    this.mediaQuery.addListener(this.onMediaQuery)
  }

  componentWillUnmount() {
    this.mediaQuery.removeListener(this.onMediaQuery)
  }

  onMediaQuery = ({ matches }) => {
    this.setState({ isMobile: matches })
  }

  render() {
    // const speed = this.state.isMobile ? 0 : 5
    const speed = 0

    return (
      <TopRightCirclesCSS>
        <div className="wrapper-right">
          <ParallaxDOM className="circle circle-right-1 pc" speed={0} />
          {/* <div className="prop-right-1 pc">
                        <Parallax
                            image={image1}
                            speed={speed}
                        />
                    </div> */}
          <div className="prop-right-2 pc">
            <Parallax image={image2} speed={speed} />
          </div>
          <ParallaxDOM className="circle circle-right-2 pc" speed={0} />
          {/* <div className="prop-right-3 pc-only">
                        <Parallax
                            image = {image3}
                            speed = {speed}
                        />
                    </div> */}
          <ParallaxDOM className="circle circle-right-3 pc-only" speed={0} />
          {/* <div className="prop-right-4 pc-only">
                        <Parallax
                            image={image4}
                            speed={speed}
                        />
                    </div> */}
          <div className="prop-right-5 pc-only">
            <Parallax image={image5} speed={speed} />
          </div>
          <ParallaxDOM className="circle circle-right-4 pc-only" speed={0} />
          {/* <div className="prop-right-6 pc-only">
                        <Parallax
                            image={image6}
                            speed={speed}
                        />
                    </div> */}
          {/* <div className="prop-right-7 mobile">
                        <Parallax
                            image={image7}
                            speed={speed}
                        />
                    </div> */}
          <ParallaxDOM className="circle circle-right-5 mobile" speed={0} />
          <div className="prop-right-8 mobile">
            <Parallax image={image8} speed={speed} />
          </div>
          <ParallaxDOM className="circle circle-right-6 mobile" speed={0} />
          <div className="prop-right-9 mobile">
            <Parallax image={image7} speed={speed} />
          </div>
          <ParallaxDOM className="circle circle-right-7 pc" speed={0} />
          {/* <div className="prop-right-10 mobile">
                        <Parallax
                            image={image9}
                            speed={speed}
                        />
                    </div> */}
          <ParallaxDOM className="circle circle-right-8 mobile" speed={0} />
        </div>
      </TopRightCirclesCSS>
    )
  }
}

export default TopRightCircles
