import styled from "styled-components"

export const BlogPostContainer = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title__headerbutton {
    background-color: #efb467;
    font-size: 12px;
    margin-top: 40px;
    font-family: "DM Sans";
  }

  .title__headercontent p {
    font-size: 14px;
    line-height: 140%;
  }

  .title__mediumheader {
    flex-basis: 36%;
  }

  .post__mediumcontainer {
    margin-top: 30px;
    flex-basis: 57%;
  }

  .post__pillposts {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    > div:first-child {
      margin-top: 45px;
    }

    .title__headerbutton {
      font-size: 16px;
      margin-top: 80px;
      padding: 12px 20px;
    }

    .title__headercontent p {
      font-size: 14px;
      max-width: 351px;
    }

    .title__headercontent {
      padding-top: 15px;
    }

    .post__mediumcontainer {
      margin-top: 0;
      display: flex;
      flex-direction: column;
    }

    .post__pillposts {
      flex-direction: row;
    }

    .post__pillposts > *:not(:last-child) {
      margin-right: 15px;
    }

    .post__pillposts > * {
      flex-basis: 50%;
    }
  }
`
