import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { BlogPostContainer } from "./style"
import TitleHeading from "./TitleHeading"
import MainBlog from "./BlogPost/main"
import PillBlog from "./BlogPost/pill"
import Button from "../../Button"

const PostMedium = () => {
  const {
    allMediumUser: { nodes },
  } = useStaticQuery(
    graphql`
      query {
        allMediumUser(
          sort: { fields: posts___author___posts___createdAt, order: DESC }
          limit: 3
        ) {
          nodes {
            posts {
              title
              uniqueSlug
              virtuals {
                previewImage {
                  imageId
                }
                tags {
                  name
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <BlogPostContainer>
      <div className="title__mediumheader">
        <TitleHeading
          subheading="MEDIUM ARTICLES"
          heading="GET IN TOUCH WITH TOMORROW THROUGH OUR ARTICLES."
        />
        <div className="title__headercontent">
          <p>
            Made with love of digital trends and writing, we’re here to help you
            discover what are the current trends in the world of technology.{" "}
          </p>
          <Button
            href="https://medium.com/compfest"
            className="title__headerbutton"
          >
            Read on MEDIUM
          </Button>
        </div>
      </div>
      <div className="post__mediumcontainer">
        {(() => {
          const post =
            nodes[0].posts.length >= 2 ? nodes[0].posts[1] : nodes[0].posts[0]
          return (
            <MainBlog
              title={post.title}
              tag={
                post.virtuals.tags.length > 0 ? post.virtuals.tags[0].name : ""
              }
              imgUrl={post.virtuals.previewImage.imageId}
              uniqueSlug={post.uniqueSlug}
            />
          )
        })()}
        <div className="post__pillposts">
          {nodes.map((item, idx) => {
            const { posts } = item
            const post = posts.length >= 2 ? posts[1] : posts[0]
            return (
              idx !== 0 && (
                <PillBlog
                  key={idx}
                  title={post.title}
                  uniqueSlug={post.uniqueSlug}
                  tag={
                    post.virtuals.tags.length > 0
                      ? post.virtuals.tags[0].name
                      : ""
                  }
                  imgUrl={post.virtuals.previewImage.imageId}
                />
              )
            )
          })}
        </div>
      </div>
    </BlogPostContainer>
  )
}

export default PostMedium
