import React, { Component } from "react"
import TopLeftCirclesCSS from "./TopLeftCirclesCSS"
import Parallax from "../CircleParallax/Parallax"
import ParallaxDOM from "../CircleParallax/ParallaxDOM"

import image2 from "../../../../../assets/images/parallax_assets/jellybean_blue.png"
import image3 from "../../../../../assets/images/parallax_assets/jellybean_red2.png"
import image4 from "../../../../../assets/images/parallax_assets/jellybean_green2.png"
import image5 from "../../../../../assets/images/parallax_assets/jellybean_red.png"
import image6 from "../../../../../assets/images/parallax_assets/jellybean_blue2.png"
import image7 from "../../../../../assets/images/parallax_assets/jellybean_green3.png"

class TopLeftCircles extends Component {
  constructor(props) {
    super(props)
    this.mediaQuery =
      typeof window !== "undefined" &&
      window.matchMedia("screen and (max-width: 768px)")
    this.state = {
      isMobile: this.mediaQuery.matches,
    }
  }

  componentDidMount() {
    this.mediaQuery.addListener(this.onMediaQuery)
  }

  componentWillUnmount() {
    this.mediaQuery.removeListener(this.onMediaQuery)
  }

  onMediaQuery = ({ matches }) => {
    this.setState({ isMobile: matches })
  }

  render() {
    // const speed = this.state.isMobile ? 0 : 5
    const speed = 0

    return (
      <TopLeftCirclesCSS>
        <div className="wrapper-left">
          <ParallaxDOM className="circle circle-left-1 pc" speed={0} />
          {/* <div className="prop-left-1 pc">
                        <Parallax
                            image = {image1}
                            speed = {speed}
                        />
                    </div> */}
          <div className="prop-left-2 pc-only">
            <Parallax image={image2} speed={speed} />
          </div>
          <ParallaxDOM className="circle circle-left-2 pc" speed={0} />
          <div className="prop-left-5 mobile">
            <Parallax image={image5} speed={speed} />
          </div>
          <ParallaxDOM className="circle circle-left-4 mobile" speed={0} />
          <ParallaxDOM className="circle circle-left-5 mobile" speed={0} />
          <div className="prop-left-6 mobile">
            <Parallax image={image6} speed={speed} />
          </div>
          <div className="prop-left-3 pc">
            <Parallax image={image3} speed={speed} />
          </div>
          <ParallaxDOM className="circle circle-left-3 pc" speed={0} />
          <div className="prop-left-4 pc-only">
            <Parallax image={image4} speed={speed} />
          </div>
          <ParallaxDOM className="circle circle-left-6 mobile" speed={0} />
          <div className="prop-left-7 mobile">
            <Parallax image={image7} speed={speed} />
          </div>
          {/* <div className="prop-left-8 mobile">
                        <Parallax
                            image = {image8}
                            speed = {speed}
                        />
                    </div> */}
          <ParallaxDOM className="circle circle-left-7 mobile" speed={0} />
        </div>
      </TopLeftCirclesCSS>
    )
  }
}

export default TopLeftCircles
