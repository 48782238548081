import React from "react"

import TopLanding from "./TopLanding/TopLanding"
import ValuesCards from "./IndexValues"
import Testimony from "./Testimony"
import MascotSection from "./MascotSection"
import PostMedium from "./PostMedium"
import Records from "./Records"
import SponsorMedpar from "../SponsorMedpar"

import { LandingPageCSS } from "./LandingPageCSS"
import data from "../../../assets/data/coming-soon.json"

const LandingPage = () => (
  <LandingPageCSS>
    <TopLanding />
    <div className="">
      <div className="landingpage-space-2" />
      <div className="layout">
        <ValuesCards />
      </div>
      <div className="landingpage-space-2" />
      <Records />
      <div className="landingpage-space-3" />
      <div className="layout">
        <Testimony profiles={data.testimony} />
        <MascotSection />
        <PostMedium />
        <div className="landingpage-space-3" />
        <SponsorMedpar />
      </div>
    </div>
  </LandingPageCSS>
)

export default LandingPage
