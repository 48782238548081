import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Image from "../../image"
import Carousel from "./carousel"

const Card = styled.div`
  display: flex;
  min-height: 400px;
  width: 80%;
  margin: auto;
  align-items: center;

  div.testi-image {
    width: 30%;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }

  div.testi-text {
    h1 {
      font-size: 32px;
    }
    p {
      font-family: "itc";
      font-weight: 500;
    }
  }

  @media (max-width: 992px) {
    width: 75%;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    text-align: center;

    div.testi-image {
      width: 60%;
    }

    div.testi-text {
      h1 {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        font-size: 24px;
      }
      p {
        margin: 0 auto 24px;
        max-width: 80%;
      }
    }
  }
`

const Testimony = ({ profiles }) => {
  return (
    <Carousel>
      {profiles.map((data, index) => (
        <Card key={index}>
          <div className="testi-image">
            <Image imgName={data.image} />
          </div>
          <div style={{ width: 40, height: 24 }} />
          <div className="testi-text">
            <h1>“{data.text}”</h1>
            <p style={{ marginBottom: 2 }}>{data.profile}</p>
            <p>at {data.from}.</p>
          </div>
        </Card>
      ))}
    </Carousel>
  )
}

Testimony.propTypes = {
  profiles: PropTypes.array,
  children: PropTypes.node,
}

export default Testimony
