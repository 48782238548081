import styled from "styled-components"

export const StyledRecordContainer = styled.div`
  font-family: "itc";
  padding-bottom: 25px;

  #records {
    display: flex;
    flex-wrap: wrap;
  }

  .record-container {
    width: 33.3%;
  }

  .record {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    text-align: center;
  }

  .record-subtitle {
    font-size: 1rem;
    text-align: center;
  }

  .record-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .record-title,
  .record-subtitle {
    margin: 0;
  }

  .record-number {
    margin-top: 0.5rem;
    font-size: 2.5rem;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    .record-container {
      width: 50%;
    }

    .record-subtitle {
      font-size: 0.9rem;
    }

    .record-title {
      font-size: 1rem;
    }

    .record-number {
      font-size: 2rem;
    }
  }
`
