import styled from "styled-components"

export const LandingPageCSS = styled.div`
  overflow-x: hidden;
  .landingpage-space-1 {
    height: 40px;
  }

  .landingpage-space-2 {
    height: 180px;
  }

  .landingpage-space-3 {
    height: 90px;
  }

  @media only screen and (max-width: 767px) {
    .landingpage-space-1 {
      height: 15px;
    }

    .landingpage-space-2 {
      height: 90px;
    }

    .landingpage-space-3 {
      height: 45px;
    }
  }
`
