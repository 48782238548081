import styled from "styled-components"
// import CenterGradient from "../../../../assets/images/landing_page/center-gradient.png"
// import CenterGradientMobile from "../../../../assets/images/landing_page/center-gradient-mobile.png"

export const PhaseLandingContainer = styled.div`
  width: 100%;
  height: calc(110.17532467532467vw - 4em - 72px);
  background: linear-gradient(180deg, #000000 80%, rgba(32, 32, 32, 0) 100%);
  display: flex;
  position: relative;
  padding: 0 36px 36px;

  @media (max-width: 769px) {
    #topic {
      width: 80%;
    }

    #number {
      width: 50%;
    }

    .yellow {
      width: 80%;
    }
    height: calc(455.17532467532467vw - 4em - 72px);
    flex-direction: column;
  }
  @media (max-width: 541px) {
    height: calc(505.17532467532467vw - 4em - 72px);

    #topic {
      width: 75%;
    }
  }
  @media (max-width: 426px) {
    height: calc(515.17532467532467vw - 4em - 72px);
  }
  @media (max-width: 376px) {
    height: calc(535.17532467532467vw - 4em - 72px);
  }
  @media (max-width: 321px) {
    height: calc(565.17532467532467vw - 4em - 72px);
  }
  @media (max-width: 281px) {
    height: calc(585.17532467532467vw - 4em - 72px);
  }
  @media (min-width: 1861px) {
    height: calc(75.17532467532467vw - 4em - 72px);
  }

  .gap-16px {
    margin: 16px 0 0 0;
  }

  #content {
    padding: 36px;
    position: absolute;
    left: -36px;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-family: DM Sans;
      font-style: normal;
      font-weight: normal;
    }

    center {
      margin-top: 32px;
    }

    .yellow {
      color: #ffcb15;
    }
  }

  #comp {
    position: absolute;
    left: 42vmax;
    top: 38.5vmax;
    font-style: normal;
    font-weight: bold;
    font-size: 20vmax;
    line-height: 140%;
    background: linear-gradient(
      89.96deg,
      #49ab64 -3.47%,
      #dc7929 35.23%,
      #e72836 64.45%,
      #7723bc 107.41%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.1;
    @media (min-width: 1861px) {
      left: 40vmax;
      top: 17vmax;
    }
    @media (max-width: 1025px) {
      left: 42vmax;
      top: 51vmax;
    }
    @media (max-width: 769px) {
      visibility: hidden;
    }
  }

  #fest {
    position: absolute;
    left: -0.1vmax;
    top: 5vmax;
    font-style: normal;
    font-weight: bold;
    font-size: 20vmax;
    line-height: 140%;
    background: linear-gradient(
      89.96deg,
      #49ab64 -3.47%,
      #dc7929 35.23%,
      #e72836 64.45%,
      #7723bc 107.41%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.1;
    @media (min-width: 1861px) {
      left: 4vmax;
      top: -2vmax;
    }
    @media (max-width: 1025px) {
      left: -6vmax;
      top: 5vmax;
    }

    @media (max-width: 769px) {
      visibility: hidden;
    }
  }

  .no-margin {
    margin: 0 !important;
  }

  .hide {
    display: none;
  }
`

export const Flex = styled.div`
  position: relative;
  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  /* col */
  .col-12,
  .col-lg-4 {
    position: relative;
    width: 100%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    @media (max-width: 769px) {
      width: 72vw;
    }
  }

  @media (min-width: 769px) {
    .col-lg-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
  }
`

export const TopLandingCSS = styled.div`
  overflow-x: hidden;
  position: relative;
  background: #000000;
  height: 300vw;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 0;
  font-size: 1.4em;

  .rainbow {
    height: 100%;
  }
  .center-column {
    height: auto;
    width: 25vw;
    @media (max-width: 769px) {
      width: 24vw;
    }
  }

  .top {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    // margin-top: 9.5em;
    font-size: 1em;
  }
  .center {
    z-index: 3;
    @media (max-width: 450px) {
      font-size: 1.2em;
    }
  }
  .main-logo {
    width: 6.7em;
    @media (max-width: 769px) {
      width: 8em;
    }
  }

  .title {
    margin: 0;
    padding-top: 0.05em;
    font-size: 5.7em;
    text-align: center;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    @media (max-width: 769px) {
      font-size: 3em;
    }
  }

  .top p {
    font-family: "DM Sans";
    color: white;
    font-weight: normal;
    font-size: 1.1em;
    text-align: center;
  }

  .top-desc {
    margin-top: 2em;
    @media (max-width: 795px) {
      margin-top: 0em;
      width: 5em;
    }
    @media (max-width: 769px) {
      width: 17em;
      font-size: 1em;
      margin-top: 2.5em;
    }
  }

  .bottom-desc {
    width: 22em;
  }

  .msg {
    margin-top: 2em;
    font-weight: 500;
    font-size: 2.1em;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    @media (max-width: 769px) {
      // margin-top: 4em;
      font-size: 1.5em;
    }
  }

  /* description */
  .description {
    margin-top: 2rem;
    @media (max-width: 1025px) {
      margin-top: 0rem;
    }
  }

  .row * {
    font-size: 1em;
    @media (max-width: 769px) {
      justify-content: center;
    }
  }

  .desc-img-left {
    display: flex;
    align-items: flex-start;
    z-index: 1;
  }

  .desc-img-right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 1;
  }

  .row-2 {
    margin-top: 30em;
    @media (max-width: 769px) {
      margin-top: 4.5em;
    }
    @media (max-width: 450px) {
      margin-top: 1em;
    }
  }

  #img1-container {
    background: linear-gradient(
      0deg,
      rgba(55, 34, 142, 0.2),
      rgba(55, 34, 142, 0.2)
    );
    background-size: cover;
    margin-left: -3em;
  }

  #img2-container {
    background: linear-gradient(
      0deg,
      rgba(55, 34, 142, 0.2),
      rgba(55, 34, 142, 0.2)
    );
    background-size: cover;
    margin-top: 10em;
  }

  #img3-container {
    background: linear-gradient(
      0deg,
      rgba(55, 34, 142, 0.2),
      rgba(55, 34, 142, 0.2)
    );
    background-size: cover;
  }

  #img4-container {
    background: linear-gradient(
      0deg,
      rgba(55, 34, 142, 0.2),
      rgba(55, 34, 142, 0.2)
    );
    background-size: cover;
    margin-top: 12em;
  }

  #img1 {
    margin-top: 6rem;
    width: 30em;
    height: 100%;
  }

  #img2 {
    margin-top: 11rem;
    width: 30em;
  }

  #img3 {
    margin-top: 7rem;
    width: 28em;
  }

  #img4 {
    margin-top: 13rem;
    width: 28em;
  }

  .desc-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25em;
    z-index: 3;
    @media (max-width: 769px) {
      height: auto;
      margin: 3em 0;
    }
    @media (max-width: 450px) {
      margin: 1em 0;
    }
  }

  p.p1 {
    width: 24em;
    font-family: itc;
    font-weight: 500;
    font-size: 0.96em;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    @media (max-width: 769px) {
      width: 75vw;
      font-size: 1.2em;
    }
    @media (max-width: 450px) {
      font-size: 1.3em;
      width: 21em;
    }
    @media (max-width: 376px) {
      font-size: 1.25em;
    }
  }

  p.p2 {
    margin-top: 2em;
    font-size: 0.85em;
    width: 31em;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    @media (max-width: 769px) {
      width: 75vw;
      font-size: 1.13em;
    }
    @media (max-width: 450px) {
      font-size: 1.1em;
      width: 24.5em;
    }
  }

  /* End Description */

  .prop-top {
    width: 33.3333%;
    height: 100%;
  }

  .prop-bottom-left {
    margin-top: 4.5625em;
    margin-right: 8.25em;
  }

  .prop-bottom-right {
    margin-top: 8.75em;
    margin-left: 21em;
  }

  .main-row {
    display: flex;
    width: 100%;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .last-row {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1861px) {
    height: 180vw;
  }

  @media (max-width: 1860px) {
    font-size: 1em;
  }

  @media (max-width: 1328px) {
    .desc-img-right .desc-img {
      margin-right: -7em;
    }

    .desc-img-left .desc-img {
      margin-left: -5em;
    }
  }

  @media (max-width: 1177px) {
    height: 350vw;
    .desc-img-right .desc-img {
      margin-right: -14em;
    }

    .desc-img-left .desc-img {
      margin-left: -12em;
    }
  }

  @media (max-width: 992px) {
    font-size: 0.8em;

    .description .desc-img {
      font-size: 0.85em;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 960px) {
    .desc-img-right .desc-img {
      margin-right: -10vw;
    }

    .desc-img-left .desc-img {
      margin-left: -10vw;
    }
  }

  @media (max-width: 795px) {
    .desc-img-right .desc-img {
      margin-right: -15vw;
    }

    .desc-img-left .desc-img {
      margin-left: -15vw;
    }
  }

  @media (max-width: 769px) {
    height: 830vw;
    font-size: 1.5em;

    .description .desc-img {
      margin: 0 !important;
      width: 70vw !important;
      height: auto;
    }

    #img1-container,
    #img2-container,
    #img3-container,
    #img4-container {
      margin: 1.2em 0;
    }
  }
  @media (max-width: 541px) {
    height: 1000vw;
  }

  @media (max-width: 450px) {
    height: 920vw;
    font-size: 0.8em;

    .description .desc-img {
      width: 32em !important;
    }

    #img1-container,
    #img2-container,
    #img3-container,
    #img4-container {
      margin: 3em 0;
    }
  }

  @media (max-width: 376px) {
    height: 940vw;
    .description .desc-img {
      width: 30em !important;
    }

    #img1-container,
    #img2-container,
    #img3-container,
    #img4-container {
      margin: 2em 0;
    }
  }
  @media (max-width: 321px) {
    font-size: 0.65em;
  }
  @media (max-width: 281px) {
    height: 1000vw;
  }
`
