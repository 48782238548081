import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import TopLeftCircles from "./CircleParallaxes/TopLeftCircles"
import TopRightCircles from "./CircleParallaxes/TopRightCircles"

import Img from "gatsby-image"
import Image from "../../image"

import { TopLandingCSS, Flex } from "./TopLandingCSS"
import PhaseLanding from "./PhaseLanding"

const TopLanding = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(
        relativePath: { in: "images/landing_page/center-gradient-mobile.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(
        relativePath: { in: "images/landing_page/center-gradient.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // console.log(data)

  const sources = [
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 769px)`,
    },
  ]

  return (
    <Flex>
      <TopLandingCSS>
        <TopLeftCircles />
        <div className="center-column">
          <Img className="rainbow" fluid={sources} alt="" />
        </div>
        <TopRightCircles />
        <div className="top">
          <PhaseLanding />
          <div className="description">
            <div className="row row-1">
              <div className="col-12 col-lg-4 desc-img-left">
                <div className="desc-img" id="img1">
                  <Image imgName="desc-img1.jpg" alt="img1" />
                </div>
              </div>
              <div className="col-12 col-lg-4 desc-text">
                <p className="p1">
                  COMPFEST is an annual one-stop IT event run by students of the
                  Faculty of Computer Science, University of Indonesia.
                </p>
                <p className="p2">
                  As the biggest IT event run by students, it has been held
                  annually since its very first event in 2009. Since then, it
                  has evolved to become a core part of the Faculty of Computer
                  Science&apos;s culture. Every year, we strive to deliver a
                  different core message and theme, making it distinct from one
                  another.
                </p>
              </div>
              <div className="col-12 col-lg-4 desc-img-right">
                <div className="desc-img" id="img2">
                  <Image imgName="desc-img2.jpg" alt="img2" />
                </div>
              </div>
            </div>
            <div className="row row-2">
              <div className="col-12 col-lg-4 desc-img-left">
                <div className="desc-img" id="img3">
                  <Image imgName="desc-img3.jpg" alt="img3" />
                </div>
              </div>
              <div className="col-12 col-lg-4 desc-text">
                <p className="p1">
                  The 12th COMPFEST has arrived to deliver technological
                  breakthrough with a series of Academy, Competition, and Event
                </p>
                <p className="p2">
                  We have collaborated with major companies to help introduce
                  the latest technology trends to our participants. Throughout
                  years, we strive to share the best that technology has to
                  offer with our three main activities: Academy, Competition,
                  and Event.
                </p>
              </div>
              <div className="col-12 col-lg-4 desc-img-right">
                <div className="desc-img" id="img4">
                  <Image imgName="desc-img4.jpg" alt="img4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </TopLandingCSS>
    </Flex>
  )
}

export default TopLanding
