import React, { Component } from "react"
import { PhaseLandingContainer } from "./TopLandingCSS"
import Button from "../../Button"
import number from "../../../../assets/images/compfest-talks/number.svg"
import topic from "../../../../assets/images/compfest-talks/topics.svg"
class PhaseLanding extends Component {
  render() {
    return (
      <PhaseLandingContainer>
        <p id="fest">FEST</p>
        <p id="comp">COMP</p>
        <div id="content">
          <div className="center">
            <p className="top-desc">
              <b>The Biggest IT Event Held by Students</b>
            </p>
            <h1 className="title">COMPFEST 12</h1>
            <p className="bottom-desc">
              “Empowering Technological Breakthrough in Shifting Towards a
              Digital Culture”
            </p>
            <div className="gap-16px" />
            <div className="gap-16px" />
            <p className="yellow">
              Participate on our virtual talk shows and webinars, known as
              COMPFEST TALKS!
            </p>
            <div className="gap-16px" />
            <div className="gap-16px" />
            <img id="number" src={number} alt="number" />
            <div className="gap-16px" />
            <div className="gap-16px" />
            <img id="topic" src={topic} alt="topic" />
            <div className="gap-16px" />
            <div className="gap-16px" />
            <div className="gap-16px" />
            <Button to="/compfesttalks">
              <span
                style={{
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "bold",
                }}
              >
                VISIT COMPFEST TALKS
              </span>
            </Button>
          </div>
        </div>
      </PhaseLandingContainer>
    )
  }
}

export default PhaseLanding
