import React from "react"

import Carousel from "../../CarouselLanding"
import Card from "./ValuesCard"

import innovative from "../../../../assets/images/common/innovative.png"
import impactful from "../../../../assets/images/common/impactfull.png"
import colaborative from "../../../../assets/images/common/collaborative.png"
import "./style.css"

const data = [
  {
    title: "CORE VALUES",
    subtitle: "COMPFEST, IN 3 WORDS.",
    cards: [
      {
        title: "INNOVATIVE",
        desc:
          "We emphasize innovativeness in a rapidly changing technological world.",
        bgColor: "linear-gradient(313.86deg, #051B57 0%, #0EB1E0 100%);",
        image: innovative,
      },
      {
        title: "COLLABORATIVE",
        desc:
          "We encourage collaborativeness in a largely individualistic society.",
        bgColor: "linear-gradient(313.86deg, #6E0D23 0.01%, #F0195E 100%);",
        image: colaborative,
      },
      {
        title: "IMPACTFUL",
        desc:
          "From our events, we strive to leave an impact in our audience's lives.",
        bgColor: "linear-gradient(313.86deg, #104A4F 0%, #32C9AD 99.99%);",
        image: impactful,
      },
    ],
  },
]

export default () => {
  const resultLst = []
  for (let i = 0; i < data.length; ++i) {
    const item = data[i]
    resultLst.push(
      <div key={item.title} css={{ textAlign: "center" }}>
        <h3 css={{ fontFamily: "DM Sans" }}>{item.title}</h3>
        <h2 css={{ marginBottom: "1.5rem" }}>{item.subtitle}</h2>
        <Carousel>
          {item.cards.map(({ title, desc, bgColor, image }) => (
            <Card key={title} title={title} bgColor={bgColor} imgName={image}>
              {desc}
            </Card>
          ))}
        </Carousel>
      </div>
    )
  }
  return <div>{resultLst}</div>
}
