import styled from "styled-components"

const circlesCSS = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 1)
  );
  display: flex;
  font-size: 16px;
  width: 39.5vw;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  .wrapper-left {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
    margin-top: 80%;
    @media (min-width: 1861px) {
      margin-top: 50%;
    }
    @media (max-width: 1210px) {
      width: 85%;
    }
    @media (max-width: 1080px) {
      margin-top: 80%;
      width: 90%;
    }
    @media (max-width: 1010px) {
      width: 95%;
    }
    @media (max-width: 769px) {
      margin-top: 750%;
    }
    @media (max-width: 541px) {
      height: 100%;
      margin-top: 850%;
      width: 90%;
    }
    @media (max-width: 426px) {
      margin-top: 1330px;
    }
    @media (max-width: 281px) {
      margin-top: 1150px;
    }
  }
  .circle-left-1,
  .circle-left-2,
  .circle-left-3,
  .circle-left-4,
  .circle-left-5,
  .circle-left-6,
  .circle-left-7 {
    z-index: -5;
  }
  .prop-left-1,
  .prop-left-2,
  .prop-left-3,
  .prop-left-4,
  .prop-left-5,
  .prop-left-6,
  .prop-left-7,
  .prop-left-8 {
    z-index: 2;
  }

  .circle {
    width: 30em;
    height: 170em;
    border-radius: 50%;
    filter: blur(13em);
  }
  .circle-left-1 {
    margin-left: -3%;
    background-color: #3f1f8d;
  }
  .circle-left-2 {
    margin-top: -10rem;
    background-color: #074a82;
  }
  .circle-left-3 {
    margin-top: -20rem;
    margin-left: 6%;
    background-color: #8c1231;
  }

  .prop-left-1,
  .prop-left-3,
  .prop-left-6 {
    width: 10em;
    height: 10em;
  }
  .prop-left-2,
  .prop-left-4,
  .prop-left-5,
  .prop-left-7,
  .prop-left-8 {
    width: 16em;
    height: 16em;
  }
  .prop-left-1 {
    margin-left: -3%;
    margin-top: -14rem;
  }
  .prop-left-2 {
    margin-left: -10%;
    margin-top: 14rem;
  }
  .prop-left-3 {
    margin-left: 28%;
    margin-top: 27.75rem;
  }
  .prop-left-4 {
    margin-top: 900px;
    margin-left: -10%;
  }
  .mobile {
    display: none;
  }
  .pc-only {
    display: block;
  }
  @media (max-width: 1210px) {
    width: 38vw;
  }
  @media (max-width: 1080px) {
    width: 37vw;
  }
  @media (max-width: 1010px) {
    width: 36vw;
  }
  @media (max-width: 992px) {
    width: 39.5vw;
    .prop-left-1 {
      margin-top: -10rem;
    }
    .prop-left-3 {
      margin-left: 30%;
    }
    .prop-left-2 {
      margin-top: 7rem;
    }
  }
  @media (max-width: 960px) {
    width: 38vw;
  }
  @media (max-width: 850px) {
    width: 37vw;
  }
  @media (max-width: 790px) {
    width: 36vw;
  }
  @media (max-width: 769px) {
    width: 39.5vw;
    font-size: 0.8rem;
    .circle {
      height: 60em;
    }
    .mobile {
      display: block;
    }
    .pc-only {
      display: none;
    }

    .circle {
      width: 18em;
      height: 18em;
      filter: blur(8em);
    }
    .circle-left-2 {
      margin-left: -50%;
    }
    .circle-left-4 {
      background-color: #8c1231;
      margin-top: -15rem;
      margin-left: -35%;
    }
    .circle-left-5 {
      background-color: #074a82;
      margin-top: 10rem;
      margin-left: 50%;
    }
    .circle-left-3 {
      margin-left: -5%;
      margin-top: -15rem;
    }
    .circle-left-6 {
      background-color: #267b6f;
      margin-top: 5rem;
      margin-left: 50%;
    }
    .circle-left-7 {
      background-color: #074a82;
      margin-top: -15rem;
      margin-left: -50%;
    }

    .prop-left-1 {
      margin-left: 35%;
      margin-top: -8rem;
    }
    .prop-left-2 {
      margin-left: -20%;
      margin-top: 3rem;
    }
    .prop-left-5 {
      margin-top: 14rem;
      margin-left: -25%;
    }
    .prop-left-6 {
      margin-top: -8rem;
      margin-left: 35%;
    }
    .prop-left-3 {
      margin-top: 200rem;
      margin-left: 0;
    }
    .prop-left-7 {
      margin-top: -12rem;
      margin-left: 50%;
    }
    .prop-left-8 {
      margin-left: -50%;
      margin-top: 5em;
    }
  }
  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
  @media (max-width: 541px) {
    .prop-left-5 {
      margin-top: 20rem;
    }
  }
  @media (max-width: 414px) {
    font-size: 0.5rem;
    .circle-left-1,
    .circle-left-2,
    .circle-left-3,
    .circle-left-4,
    .circle-left-5,
    .circle-left-6,
    .circle-left-7 {
      position: absolute;
      margin: 0;
    }
    .prop-left-1,
    .prop-left-2 {
      height: 10em;
      width: 10em;
    }
    .circle {
      height: 20em;
      width: 20em;
      filter: blur(8em);
    }
    .circle-left-1 {
      top: -5%;
      left: 5%;
    }
    .circle-left-2 {
      top: 2%;
      left: -20%;
    }
    .circle-left-4 {
      top: 10%;
      left: -20%;
    }
    .circle-left-5 {
      top: 20%;
    }
    .circle-left-3 {
      top: 87%;
      left: -20%;
    }
    .circle-left-6 {
      top: 93%;
    }
    .circle-left-7 {
      top: 100%;
      left: -5%;
    }

    .prop-left-1 {
      margin-left: 35%;
      margin-top: -3rem;
    }
    .prop-left-2 {
      margin-left: 10%;
    }
    .prop-left-5 {
      margin-top: 10rem;
      margin-left: -40%;
    }
    .prop-left-6 {
      margin-top: 7rem;
      margin-left: 20%;
    }
    .prop-left-3 {
      margin-top: 115rem;
    }
    .prop-left-7 {
      margin-top: 0;
      margin-left: 20%;
    }
    .prop-left-8 {
      margin-top: 2rem;
      margin-left: -50%;
    }
  }
  @media (max-width: 376px) {
    .prop-left-3 {
      margin-top: 100rem !important;
    }
  }
  @media (max-width: 360px) {
    .circle-left-3 {
      top: 90%;
    }
    .circle-left-6 {
      top: 96%;
    }
  }
  @media (max-width: 321px) {
    .prop-left-3 {
      margin-top: 75rem;
    }
    .circle-left-4 {
      top: 12%;
    }
    .circle-left-5 {
      top: 24%;
    }
    .circle-left-3 {
      top: 83%;
    }
    .circle-left-6 {
      top: 90%;
    }
    .circle-left-7 {
      top: 98%;
    }
    .prop-left-3 {
      margin-top: 75rem !important;
    }
  }
`

export default circlesCSS
