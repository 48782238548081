import React from "react"
import EmblaCarouselReact from "embla-carousel-react"
import "./style.css"

const Carousel = ({ children }) => {
  let draggable = false
  try {
    draggable = window.innerWidth < 768
  } catch (e) {
    draggable = false
  }

  return (
    <div className="carousel">
      <div className="carousel__wrap">
        <EmblaCarouselReact
          className="carousel__viewport"
          options={{ align: "start", draggable: draggable }}
          htmlTagName="div"
        >
          <div className="carousel__container">
            {children.map((Child, index) => (
              <div className="carousel__item" key={index}>
                {Child}
              </div>
            ))}
          </div>
        </EmblaCarouselReact>
      </div>
    </div>
  )
}

export default Carousel
