import React, { Component } from "react"
import Image from "../../image"
import { Styles } from "./style"

class MascotSection extends Component {
  render() {
    return (
      <Styles>
        <div className="mascot-left">
          <p>MASCOT</p>
          <h2>MEET EV!</h2>
          <p>
            Our mascot, EV (read: ee-vee) comes from the word ‘twElVe’. EV will
            join you at the long awaited journey to empower breakthrough towards
            the shifting digital culture.
          </p>
        </div>
        <div className="mascot-right">
          <div className="eve-wrapper">
            <Image imgName="eve_merah.png" alt="Eve" />
          </div>
        </div>
      </Styles>
    )
  }
}

export default MascotSection
