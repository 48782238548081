import styled from "styled-components"

export const Styles = styled.div`
  padding-top: 100px;
  padding-bottom: 150px;
  display: flex;

  h2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .mascot-left {
    width: 34%;
  }

  .mascot-left p:first-of-type {
    font-size: 1.1em;
  }

  .mascot-right canvas {
    outline: none;
  }

  .mascot-right {
    padding-left: 20px;
    width: 66%;

    .eve-wrapper {
      max-width: 400px;
      margin: auto;
    }
  }

  .mascot-right img {
    width: 100%;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-bottom: 75px;

    h2 {
      margin-top: 6px;
      margin-bottom: 18px;
    }

    .mascot-left {
      width: 100%;
      padding-top: 35px;
    }

    .mascot-right {
      padding-left: 0;
      width: 100%;
    }
  }
`
