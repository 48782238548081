import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
  text-decoration: none;
  border-radius: 10px;
  background: ${(props) => props.bgColor.replace("164", "150")};
  overflow: hidden;
  margin-right: 15px;
  margin-left: 15px;
  height: 100%;

  .logo {
    margin: 2.5rem 0 1rem 0;
  }

  h4 {
    margin: 0;
    padding: 30px 35px 20px;
    font-family: "itc";
    font-size: 18px;
    transition: padding 0.2s ease;
  }

  p {
    margin: 0;
    font-family: "DM Sans", "itc";
    font-size: 0.9rem;
    padding: 0 45px 3rem;
  }

  @media (max-width: 576px) {
    margin-right: 10px;
    margin-left: 10px;
  }
`

const Card = ({ title, children, imgName, bgColor }) => (
  <Container bgColor={bgColor}>
    <img src={imgName} alt={title} className="logo" />
    <h4>{title}</h4>
    <p>{children}</p>
  </Container>
)

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
  imgName: PropTypes.string,
  url: PropTypes.string,
}

export default Card
