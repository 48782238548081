import styled from "styled-components"

const circlesCSS = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 1)
  );
  display: flex;
  font-size: 16px;
  height: 100%;
  width: 39.5vw;
  overflow: hidden;

  .circle-right-1,
  .circle-right-2,
  .circle-right-3,
  .circle-right-4,
  .circle-right-5,
  .circle-right-6,
  .circle-right-7,
  .circle-right-8 {
    z-index: -5;
  }
  .prop-right-1,
  .prop-right-2,
  .prop-right-3,
  .prop-right-4,
  .prop-right-5,
  .prop-right-6,
  .prop-right-7,
  .prop-right-8,
  .prop-right-9,
  .prop-right-10 {
    z-index: 2;
  }

  .circle {
    width: 30em;
    height: 170em;
    border-radius: 50%;
    filter: blur(13em);
  }

  .circle-right-1 {
    background-color: #267b6f;
    margin-left: 50%;
    margin-top: -10rem;
  }
  .circle-right-2 {
    background-color: #c04925;
    margin-top: -15rem;
    margin-left: -10%;
  }
  .circle-right-3 {
    background-color: #074a82;
    margin-left: -15%;
    margin-top: 20rem;
  }
  .circle-right-4 {
    background-color: #4f1679;
    margin-left: -20%;
  }

  .prop-right-1,
  .prop-right-4,
  .prop-right-5 {
    height: 10em;
    width: 10em;
  }
  .prop-right-3,
  .prop-right-6,
  .prop-right-7,
  .prop-right-8,
  .prop-right-9,
  .prop-right-10 {
    height: 16em;
    width: 16em;
  }
  .prop-right-1 {
    margin-left: 50%;
    margin-top: -12rem;
  }
  .prop-right-2 {
    height: 14em;
    width: 14em;
    margin-top: 16rem;
    margin-left: 40%;
  }
  .prop-right-3 {
    margin-left: 78%;
    margin-top: -5rem;
  }
  .prop-right-4 {
    margin-left: 70%;
    margin-top: -5rem;
  }
  .prop-right-5 {
    margin-top: 20rem;
    margin-left: 30%;
  }
  .prop-right-6 {
    margin-top: -24rem;
    margin-left: 60%;
  }

  .wrapper-right {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
    margin-top: 155%;
    @media (min-width: 1861px) {
      margin-top: 123%;
    }
    @media (max-width: 1210px) {
      width: 85%;
    }
    @media (max-width: 1080px) {
      margin-top: 180%;
      width: 90%;
    }
    @media (max-width: 1010px) {
      width: 95%;
    }
    @media (max-width: 769px) {
      margin-top: 680%;
    }
    @media (max-width: 600px) {
      height: 100%;
      margin-top: 1020%;
      width: 90%;
    }
    @media (max-width: 415px) {
      margin-top: 950%;
    }
    @media (max-width: 361px) {
      margin-top: 1080%;
    }
    @media (max-width: 281px) {
      margin-top: 1100%;
    }
  }

  .pc-only {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 1210px) {
    width: 38vw;
  }
  @media (max-width: 1080px) {
    width: 37vw;
  }
  @media (max-width: 1010px) {
    width: 36vw;
  }
  @media (max-width: 992px) {
    width: 39.5vw;
  }
  @media (max-width: 960px) {
    width: 38vw;
  }
  @media (max-width: 850px) {
    width: 37vw;
  }
  @media (max-width: 790px) {
    width: 36vw;
  }
  @media (max-width: 769px) {
    width: 39.5vw;
    font-size: 0.8rem;
    .circle {
      height: 60em;
    }
    .mobile {
      display: block;
    }
    .pc-only {
      display: none;
    }

    .circle {
      width: 18em;
      height: 18em;
      filter: blur(8em);
    }

    .circle-right-1 {
      margin-top: 25rem;
    }
    .circle-right-2 {
      margin-top: -20rem;
      margin-left: 40%;
    }
    .circle-right-5 {
      background-color: #3f1f8d;
      margin-top: -12rem;
      margin-left: -35%;
    }
    .circle-right-6 {
      background-color: #074a82;
      margin-left: 15%;
      margin-top: -15rem;
    }
    .circle-right-7 {
      background-color: #3f1f8d;
      margin-top: -15rem;
      margin-left: 50%;
    }
    .circle-right-8 {
      background-color: #c04925;
      margin-top: -15rem;
      margin-left: -25%;
    }
    .prop-right-1 {
      margin-top: -10rem;
      margin-left: 25%;
    }
    .prop-right-2 {
      margin-top: 30rem;
      margin-left: 35%;
    }
    .prop-right-7 {
      margin-top: 20rem;
      margin-left: 20%;
    }
    .prop-right-8 {
      margin-top: 190rem;
      margin-left: 25%;
    }
    .prop-right-9 {
      margin-top: 15rem;
      margin-left: 80%;
    }
    .prop-right-10 {
      margin-left: 0;
      margin-top: 20rem;
    }
  }
  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
  @media (max-width: 414px) {
    font-size: 0.5rem;
    .circle-right-1,
    .circle-right-2,
    .circle-right-3,
    .circle-right-4,
    .circle-right-5,
    .circle-right-6,
    .circle-right-7 {
      position: absolute;
      margin: 0;
    }
    .circle {
      height: 20em;
      width: 20em;
      filter: blur(8em);
    }
    .circle-right-1 {
      margin-top: 10rem;
    }
    .circle-right-2 {
      top: 16%;
    }
    .circle-right-3 {
      top: 23%;
    }
    .circle-right-5 {
      top: 25%;
    }
    .circle-right-6 {
      top: 85%;
    }
    .circle-right-7 {
      top: 92%;
      left: 20%;
    }
    .circle-right-8 {
      top: 100%;
    }
    .prop-right-1 {
      margin-top: 10rem;
    }
    .prop-right-2 {
      margin-top: 18rem;
    }
    .prop-right-7 {
      margin: 0;
    }
    .prop-right-8 {
      margin-top: 108rem;
    }
    .prop-right-9 {
      margin-top: 3rem;
      margin-left: 60%;
    }
    .prop-right-10 {
      margin-top: 3rem;
    }
  }
  @media (max-width: 376px) {
    .prop-right-8 {
      margin-top: 100rem;
    }
  }
  @media (max-width: 360px) {
    .circle-right-6 {
      top: 92%;
    }
    .circle-right-7 {
      top: 100%;
    }
  }
  @media (max-width: 321px) {
    .prop-right-1 {
      margin-top: 7rem;
    }
    .prop-right-2 {
      margin-top: 14rem;
    }
    .prop-right-7 {
      margin-top: 2rem;
    }
    .prop-right-8 {
      margin-top: 75rem;
    }
    .prop-right-10 {
      margin-top: -1rem;
    }
  }
`

export default circlesCSS
