import React, { Component } from "react"
import PropTypes from "prop-types"
import Rellax from "rellax"

export class Parallax extends Component {
  constructor(props) {
    super(props)
    this.state = {
      speed: props.speed,
      image: props.image,
      style: {
        height: "100%",
        width: "100%",
      },
    }
  }

  shouldComponentUpdate() {
    return false
  }

  componentDidMount() {
    // window.addEventListener("scroll", this.handleScroll);
    this.rellax = new Rellax(this.refs.rellax, {
      speed: this.state.speed,
    })
  }

  render() {
    return (
      <img
        style={this.state.style}
        src={this.props.image}
        alt=""
        ref="rellax"
        className={this.props.className}
      />
    )
  }
}

Parallax.propTypes = {
  image: PropTypes.string.isRequired,
  speed: PropTypes.number.isRequired,
}

export default Parallax
